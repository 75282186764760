import type { WretchError } from 'wretch';

export function isHTTPClientError(error: unknown): error is WretchError {
  return (
    typeof error === 'object' &&
    error !== null &&
    Object.hasOwn(error, 'status') &&
    Object.hasOwn(error, 'url') &&
    (error as { response: Response }).response instanceof Response
  );
}
